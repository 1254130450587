$(function() {
  var $pagination = $('#pagination');
  if ($pagination.length == 0) return;

  var $more = $pagination.find('.c-pagination__more');
  var $loader = $pagination.find('.c-pagination__loader');
  var $items = $pagination.find('.c-pagination__items');

  $items.hide();

  var $next = $items.find('.c-pagination__item--current').next();
  if ($next.length == 0) return;

  $more.css('display', 'inline-block').on('click', function(e) {
    e.preventDefault();

    $more.hide();
    $loader.css('display', 'inline-block');

    $.get($next.find('a').attr('href'), function(data, textStatus, jqXHR) {
      var $html = $(data);

      var $items = null;
      if ($html.find('.c-products').length > 0) {
        $items = $html.find('.c-products .c-products__items').html();
        $('.c-products .c-products__items').append($items);
      }
      else if ($html.find('.c-recipes').length > 0) {
        $items = $html.find('.c-recipes .c-recipes__items').html();
        $('.c-recipes .c-recipes__items').append($items);
      }
      else if ($html.find('.c-joboffers').length > 0) {
        $items = $html.find('.c-joboffers .c-joboffers__items').html();
        $('.c-joboffers .c-joboffers__items').append($items);
      }

      myLazyLoad.update();

      if (!$items) return;

      $loader.hide();
      $next = $html.find('.c-pagination__items .c-pagination__item--current').next();
      if ($next.length > 0) {
        $more.css('display', 'inline-block');
      }
    });
  });
});
