$(function() {
  $('form').find('input, textarea').on('focus', function() {
    var $this = $(this);
    $this.closest('.c-form__fieldset').addClass('c-form__fieldset--focus');
  }).on('blur', function() {
    var $this = $(this);
    if ($.trim($this.val())) return;
    $this.closest('.c-form__fieldset').removeClass('c-form__fieldset--focus');
  });
});
